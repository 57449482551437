@use '../../../styles/breakpoints.module';

.section-heading {
  align-items: baseline;
  display: flex;

  &__title {
    color: var(--component-section-heading-title-color);
    margin-right: var(--space-4);
  }
}
