@use '../../../styles/breakpoints.module';

.home-personalised-section--heading {
  margin: 0 var(--space-2) var(--space-6) var(--space-2);
}

.home-personalised-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--space-6);

  @include breakpoints.for-large-up {
    flex-direction: row;
  }

  &__partner-hero-card {
    border-radius: var(--space-2);
    box-shadow: var(--util-shadow);
    display: flex;
    flex: 1;
  }

  &__hero-cta {
    padding: 0 var(--space-6) var(--space-6) var(--space-6);

    a {
      padding: 0;
    }
  }

  &__partner-hero-card--link {
    border-radius: var(--space-2);
    box-shadow: 4px 4px 4px rgb(0 0 0 / 10%);
    display: flex;
    flex: 1;
    text-decoration: none;
  }

  &__tabs {
    flex: 1;
    height: fit-content;

    @include breakpoints.for-large-up {
      max-width: 50%;
    }

    > div {
      @include breakpoints.for-medium-up {
        height: 469px;
      }

      > ul {
        button {
          font-size: var(--space-6);
        }
      }

      > div {
        height: calc(100% - 60.78px);
      }
    }
  }
}
