.tabs {
  border-radius: var(--component-tabs-container-border-radius);
  box-shadow: var(--component-tabs-container-shadow);

  &__list {
    display: flex;
    gap: var(--space-2);
    justify-content: space-between;
    list-style: none;
    margin-bottom: 0;
    margin-left: 0;
    overflow: auto;

    li {
      flex-grow: 1;
    }
  }

  &__tab {
    background-image: var(--component-tabs-background);
    border: none;
    border-radius: var(--component-tabs-border-radius);
    box-shadow: var(--component-tabs-shadow);
    box-sizing: border-box;
    color: var(--component-tabs-color);
    cursor: pointer;
    font-weight: var(--component-tabs-font-weight);
    margin: 0;
    padding: var(--space-4);
    position: relative;
    white-space: nowrap;
    width: 100%;

    &--active {
      background-color: var(--component-tabs-active-background);
      background-image: none;
      color: var(--component-tabs-active-color);
    }
  }

  &__panel {
    background-color: var(--component-tabs-panel-background);
    border-radius: var(--component-tabs-panel-border-radius);
    position: relative;
  }
}
