@use '../../../styles/breakpoints.module';

.table {
  $block: &;

  &__table {
    background-color: var(--component-table-default-background-color);
    border-collapse: collapse;
    color: var(--component-table-default-head-color);
    font-size: var(--component-table-default-head-font-size);
    height: 100%;
    width: 100%;
  }

  &__td {
    padding: var(--space-3) var(--space-4);
    @include breakpoints.for-medium-up {
      padding: var(--space-3) var(--space-5);
    }
  }

  &__no-data {
    align-items: center;
    background-color: var(--color-neutrals-white);
    border-radius: var(--util-border-radius);
    box-shadow: var(--util-shadow);
    color: var(--component-table-no-data-color);
    display: flex;
    flex: 1;
    font-size: var(--component-table-no-data-font-size);
    justify-content: center;
    padding: var(--space-12);
  }

  &--default {
    #{$block}__table {
      border-radius: var(--util-border-radius);
      box-shadow: var(--util-shadow);
      overflow: hidden;
    }
    #{$block}__body {
      color: var(--component-table-default-color);
      font-size: var(--component-table-default-font-size);

      #{$block}__tr:last-child {
        border-bottom: none;
      }
    }
    #{$block}__tr {
      border-bottom: var(--component-table-default-border-bottom);
      height: var(--space-11);
    }
  }

  &--secondary {
    #{$block}__table {
      background-color: var(--component-table-secondary-background-color);
      border-collapse: collapse;
      color: var(--component-table-secondary-color);
      font-size: var(--component-table-secondary-font-size);
      width: 100%;
    }

    #{$block}__td {
      padding: var(--space-3);
    }

    #{$block}__head {
      border-bottom: var(--component-table-secondary-border-bottom);

      td {
        color: var(--component-table-secondary-head-color);
      }
    }

    tbody tr:nth-child(odd) {
      background-color: var(--component-table-secondary-row-background-color);
    }
  }
}
