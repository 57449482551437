@use '../../../styles/breakpoints.module';

$small-width: 500px;
$medium-width: 700px;
$large-width: 920px;
$xlarge-width: 1084px;
$card-margin-top: -140px;
$hero-image-height-small: 317px;
$hero-image-height-medium: 367px;

.search-section {
  display: flex;
  flex-direction: column;

  &__image {
    display: flex;
    height: $hero-image-height-small;
    justify-content: center;
    position: relative;

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      width: 100%;
    }

    @include breakpoints.for-medium-up {
      height: $hero-image-height-medium;
    }
  }

  &__eyebrow {
    margin-bottom: var(--space-5);
  }

  &__card {
    align-items: center;
    background: var(--alto-sem-color-bg-layer2-default, --color-neutrals-white);
    border: var(--alto-sem-border-width-sm, 1px) solid
      var(--alto-sem-color-border-tertiary, --color-neutrals-grey-5);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    margin: -140px var(--space-4) var(--space-4) var(--space-4);
    padding: var(--space-6) var(--space-4);
    text-align: center;
    z-index: 1;

    @include breakpoints.for-small-up {
      margin: $card-margin-top auto 0;
      width: $small-width;
    }

    @include breakpoints.for-medium-up {
      width: $medium-width;
    }

    @include breakpoints.for-large-up {
      width: $large-width;
    }

    @include breakpoints.for-xlarge-up {
      width: $xlarge-width;
    }
  }

  &__card-text {
    display: grid;
    gap: var(--space-2);

    p {
      margin: 0;
    }

    @include breakpoints.for-medium-up {
      max-width: 680px;
    }
  }

  &__card-button {
    margin-top: var(--space-2);
    padding: 12px;
    text-decoration: unset;
  }

  &__card-search-field-button {
    margin-top: var(--space-4);
    max-width: 512px;
    width: 100%;
  }

  &__card-search-field-icon {
    font-size: var(--text-xl);
    font-weight: var(--font-weight-regular);
    padding-right: var(--space-3);
  }

  &__card-search-field {
    align-items: center;
    border: 1px solid var(--alto-sem-color-border-primary-default, #9593a0);
    border-radius: var(--alto-textField-radius, 12px);
    display: flex;
    font-weight: var(--font-weight-regular);
    padding: 12px;
    width: 100%;
  }
}
