@use '../../../styles/breakpoints.module';

.home-page-module {
  &__heading {
    font-size: var(--text-4xl);
    margin: 0;

    @include breakpoints.for-medium-up {
      display: flex;
      gap: var(--space-2);
    }
  }

  &__section {
    background: var(--module-banners-carousel-background-color);
  }
}
