@use '../../../styles/breakpoints.module';
@use '../../../styles/vars.module';

.home-top-picks-carousel {
  &__heading {
    margin: 0 auto;
    max-width: var(--util-max-screen-size);

    h2 {
      margin: 0 var(--space-4) var(--space-2);
    }
  }

  &__item {
    @include breakpoints.for-large-up {
      &:hover {
        transform: scale(1.1);
        transition: transform 0.2s;
      }
    }
  }

  &__link {
    text-decoration: none;
  }

  &__card {
    padding: var(--space-4);
    position: relative;

    > div > div {
      position: unset;
    }
  }
}
