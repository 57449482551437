@use '../../../styles/breakpoints.module';

.promotional-banner {
  background-color: var(--component-promotional-banner-background-color);
  border-radius: var(--util-small-border-radius);
  display: block;
  padding: var(--space-4);
  text-decoration: none;

  @include breakpoints.for-small-up {
    border-radius: var(--util-border-radius);
  }

  &__title {
    color: var(--component-promotional-banner-font-color);
    font-weight: var(--component-promotional-banner-heading-font-weight);
    margin: 0 0 var(--space-2);
  }

  &__body {
    color: var(--component-promotional-banner-font-color);
    margin-block-end: var(--space-2);
  }

  &__terms {
    align-items: center;
    color: var(--component-promotional-banner-font-color);
    display: inline-flex;
    font-size: var(--text-sm);
    gap: var(--space-2);
    margin: 0;
    padding: 0;
  }
}
