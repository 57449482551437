@use '../../styles/breakpoints.module';

.search-modal {
  display: flex;
  justify-content: space-between;

  &__header {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  &__content {
    flex-grow: 1;
    max-height: auto;
    overflow-y: auto;
    padding: 0 var(--space-4);

    @include breakpoints.for-medium-up {
      max-height: 50vh;
    }
  }

  &__modal {
    border-radius: 0px;
    bottom: auto;
    min-height: 100%;
    min-width: 100%;

    @include breakpoints.for-medium-up {
      border-radius: var(--space-2);
      max-height: 600px;
      min-height: 50vh;
      min-width: 500px;
      top: 50%;
      transform: translateY(-50%);
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__search-bar {
    border-bottom: 1px solid var(--color-neutrals-grey-4);
    display: flex;
    flex-direction: row;
    gap: var(--space-2);
    padding: var(--space-2) var(--space-3) var(--space-1) var(--space-3);

    &__input {
      border: none;
      width: 100%;
    }

    &__icon {
      color: var(--color-neutrals-grey-3);
      font-size: 20px;
      margin: auto 0;
    }
  }

  &__results {
    display: flex;
    flex-direction: column;
    gap: var(--space-2);
    margin-bottom: var(--space-6);

    &__tile {
      border-bottom: 1px solid var(--color-neutrals-grey-4);
      padding: var(--space-1) 0;
    }
  }

  &__no-results {
    background-color: var(--alto-sem-color-bg-information-subtle-default);
    border-radius: var(--util-border-radius);
    margin-top: var(--space-4);
    padding: var(--space-4);
  }
}
