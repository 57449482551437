@use '../../../styles/breakpoints.module';

.home-last-purchases {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &__table-content {
    cursor: pointer;
    display: flex;
    text-decoration: none;
  }

  &__table-description-container {
    color: var(--module-home-last-purchases-color);
    display: flex;
    flex-direction: column;
    font-size: var(--module-home-last-purchases-font-size);
    gap: var(--space-1);
  }

  &__table-description-sub-container {
    color: var(--module-home-last-purchases-sub-text-color);
    display: flex;
    flex-direction: column;
    font-size: var(--module-home-last-purchases-sub-text-font-size);
    gap: var(--space-1);

    @include breakpoints.for-medium-up {
      flex-direction: row;
      gap: var(--space-3);
    }

    &__separator {
      display: none;

      @include breakpoints.for-medium-up {
        display: block;
      }
    }
  }

  &__ctas {
    border-top: 1px solid var(--module-home-favourites-border-color);
    display: flex;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    h2 {
      align-items: center;
      border-bottom: 1px solid var(--module-home-favourites-border-color);
      display: flex;
      font-size: var(--text-xl);
      font-weight: var(--module-home-favourites-heading-font-weight);
      justify-content: center;
      margin: 0;
      padding: var(--space-3);
    }
  }

  &__nothing-to-display {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: var(--space-4);

    @include breakpoints.for-medium-up {
      padding: 0px;
    }
  }

  &__nothing-to-display--heading {
    font-size: var(--text-xl);
    margin-bottom: var(--space-4);
  }

  &__nothing-to-display--paragraph {
    color: var(--module-home-favourites-nothing-to-display-paragraph-color);
    font-size: var(--text-base);
    margin: 0 var(--space-8);
    text-align: center;
  }
}

.home-last-purchases-table {
  display: flex;
  flex: 1;

  thead > tr > td {
    color: var(--module-home-last-purchases-header-color);
    font-size: var(--module-home-last-purchases-header-font-size);
    font-weight: var(--module-home-last-purchases-header-font-weight);
  }

  @include breakpoints.for-medium-up {
    tbody {
      tr {
        height: 83px;
      }
    }
  }
}
