@use '../../../styles/breakpoints.module';

.home-last-visited {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &__partner-tile {
    span {
      font-size: var(--module-home-favourites-partner-text-font-size);
    }
  }

  &__ctas {
    border-top: 1px solid var(--module-home-favourites-border-color);
    display: flex;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    h2 {
      align-items: center;
      border-bottom: 1px solid var(--module-home-favourites-border-color);
      display: flex;
      font-size: var(--text-xl);
      font-weight: var(--module-home-favourites-heading-font-weight);
      justify-content: center;
      margin: 0;
      padding: var(--space-3);
    }

    > a {
      border-bottom: 1px solid var(--module-home-favourites-border-color);
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      height: 83px;
      padding: var(--space-3);
      text-decoration: none;
    }
  }

  &__nothing-to-display {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: var(--space-4);

    @include breakpoints.for-medium-up {
      padding: 0px;
    }
  }

  &__nothing-to-display--image {
    height: 120px;
    margin-bottom: var(--space-4);
    width: 100%;

    img {
      object-fit: contain;
    }
  }

  &__nothing-to-display--heading {
    font-size: var(--text-xl);
    margin-bottom: var(--space-4);
  }

  &__nothing-to-display--paragraph {
    color: var(--module-home-favourites-nothing-to-display-paragraph-color);
    font-size: var(--text-base);
    margin: 0 var(--space-8);
    text-align: center;
  }
}
