@use '../../styles/breakpoints.module';

.home {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;

  &__loader {
    height: 100px;
  }
}
