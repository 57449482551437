@use '../../../styles/breakpoints.module';

.hero-card {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  &__link {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-decoration: none;

    > div:first-child {
      height: 200px !important;
      @include breakpoints.for-large-up {
        flex: 1;
      }
    }
  }

  &__image {
    height: 400px;
    max-height: 700px;
    width: 100%;

    img {
      border-top-left-radius: var(--space-2);
      border-top-right-radius: var(--space-2);
      object-fit: cover;
      object-position: center;
    }
  }

  &__image--border-radius-bottom {
    img {
      border-bottom-left-radius: var(--space-2);
      border-bottom-right-radius: var(--space-2);
    }
  }

  &__cta-block {
    background: var(--color-neutrals-white);
    border-bottom-left-radius: var(--space-2);
    border-bottom-right-radius: var(--space-2);
    display: flex;
  }

  &__content {
    background: var(--color-neutrals-white);
    border-bottom-left-radius: var(--space-2);
    border-bottom-right-radius: var(--space-2);
  }

  &__content-partner-tile {
    padding: var(--component-hero-card-partner-small-screen-padding);

    @include breakpoints.for-medium-up {
      padding: var(--space-6);
    }

    span {
      right: var(--space-6);
      top: var(--space-6);
    }
  }

  &__favourite {
    background-color: rgb(0 0 0 / 60%);
    height: var(--space-10);
    width: var(--space-10);

    span {
      font-size: var(--space-5);
    }
  }
}
